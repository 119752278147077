import React from 'react';
import { ClientContext } from '@thd-nucleus/app-render/dist/client';
import B2BTheme, { EPRTheme } from '@thd-olt-global/b2b-theme';
import { NewRelicBridge } from '@thd-olt-component-react/new-relic-bridge';
import { ThdAnalytics } from '@thd-olt-component-react/thd-analytics';
import cookieUtils from 'cookie-utils';
import { whyDidYouRender } from '../wdyr';
import { App } from './App';
import { getRoutes } from './routes';

ThdAnalytics.init();
NewRelicBridge.init();
whyDidYouRender.init();

document.addEventListener('DOMContentLoaded', async () => {
  const client = new ClientContext();
  client.setExperienceRoutes(getRoutes());

  if (client.serverData.themeName === 'epr') {
    client.setTheme({ name: 'epr', template: EPRTheme });
  } else if (client.customer.type === 'b2b') {
    client.setTheme({ name: 'b2b', template: B2BTheme });
  }

  client.render(<App
    cookieUtils={cookieUtils}
    prefetchPip={client.featureSwitches.prefetchPip}
    productPodHoverDelay={client.featureSwitches.productPodHoverDelay}
  />);
});
