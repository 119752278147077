import * as React from 'react';
import { shape, bool } from 'prop-types';

export const Error = ({ error, debug }) => {

  return (
    <div id="root__error" className="container">
      <div className="grid isBound">
        <div className="col__12-12">
          <div className="grid error-page" data-testid="errorContent">
            <div className="grid grid isBound error-page">
              <div className="error-page__container col__12-12">
                <img
                  src="https://assets.thdstatic.com/images/v1/oops.svg"
                  alt=""
                  className="error-page__image"
                  width="702"
                  height="205"
                />
                <h1 className="error-page__title u__display-font--black">WE&apos;RE FIXING IT.</h1>
                <div className="error-page__description">
                  <p>We&apos;ll have this patched up as soon as possible.</p>
                </div>
                {error && debug && <pre><code>{error.toString()}</code></pre>}
                {error && debug && <pre><code>{error.stack}</code></pre>}
                <div className="error-page__buttons">
                  <a
                    className="bttn bttn--primary bttn--inline
                    u__bottom--standard body__m-right-large"
                    href="/"
                  >
                    <span className="bttn__content">Back to homepage</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  error: shape({}),
  debug: bool
};

Error.defaultProps = {
  error: null,
  debug: false,
};
